//import logo from './logo.svg';
import './App.css';
import * as React from 'react';
//import Button from '@mui/material/Button';
//import Box from '@mui/material/Box';
import SumitomoDataGrid from './components/sumitomo/SumitomoDataGrid'

function App() {

  
  return (
    <div>
      <SumitomoDataGrid ></SumitomoDataGrid >
    </div>
  );
}

export default App;
