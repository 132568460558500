import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
//import Avatar from '@mui/material/Avatar';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
//import Typography from '@mui/material/Typography';
//import Checkbox from '@mui/material/Checkbox';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Link from '@mui/material/Link';

const usStates = [
  { name: 'ALABAMA', abbreviation: 'AL'},
  { name: 'ALASKA', abbreviation: 'AK'},
  { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
  { name: 'ARIZONA', abbreviation: 'AZ'},
  { name: 'ARKANSAS', abbreviation: 'AR'},
  { name: 'CALIFORNIA', abbreviation: 'CA'},
  { name: 'COLORADO', abbreviation: 'CO'},
  { name: 'CONNECTICUT', abbreviation: 'CT'},
  { name: 'DELAWARE', abbreviation: 'DE'},
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
  { name: 'FLORIDA', abbreviation: 'FL'},
  { name: 'GEORGIA', abbreviation: 'GA'},
  { name: 'GUAM', abbreviation: 'GU'},
  { name: 'HAWAII', abbreviation: 'HI'},
  { name: 'IDAHO', abbreviation: 'ID'},
  { name: 'ILLINOIS', abbreviation: 'IL'},
  { name: 'INDIANA', abbreviation: 'IN'},
  { name: 'IOWA', abbreviation: 'IA'},
  { name: 'KANSAS', abbreviation: 'KS'},
  { name: 'KENTUCKY', abbreviation: 'KY'},
  { name: 'LOUISIANA', abbreviation: 'LA'},
  { name: 'MAINE', abbreviation: 'ME'},
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
  { name: 'MARYLAND', abbreviation: 'MD'},
  { name: 'MASSACHUSETTS', abbreviation: 'MA'},
  { name: 'MICHIGAN', abbreviation: 'MI'},
  { name: 'MINNESOTA', abbreviation: 'MN'},
  { name: 'MISSISSIPPI', abbreviation: 'MS'},
  { name: 'MISSOURI', abbreviation: 'MO'},
  { name: 'MONTANA', abbreviation: 'MT'},
  { name: 'NEBRASKA', abbreviation: 'NE'},
  { name: 'NEVADA', abbreviation: 'NV'},
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
  { name: 'NEW JERSEY', abbreviation: 'NJ'},
  { name: 'NEW MEXICO', abbreviation: 'NM'},
  { name: 'NEW YORK', abbreviation: 'NY'},
  { name: 'NORTH CAROLINA', abbreviation: 'NC'},
  { name: 'NORTH DAKOTA', abbreviation: 'ND'},
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
  { name: 'OHIO', abbreviation: 'OH'},
  { name: 'OKLAHOMA', abbreviation: 'OK'},
  { name: 'OREGON', abbreviation: 'OR'},
  { name: 'PALAU', abbreviation: 'PW'},
  { name: 'PENNSYLVANIA', abbreviation: 'PA'},
  { name: 'PUERTO RICO', abbreviation: 'PR'},
  { name: 'RHODE ISLAND', abbreviation: 'RI'},
  { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
  { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
  { name: 'TENNESSEE', abbreviation: 'TN'},
  { name: 'TEXAS', abbreviation: 'TX'},
  { name: 'UTAH', abbreviation: 'UT'},
  { name: 'VERMONT', abbreviation: 'VT'},
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
  { name: 'VIRGINIA', abbreviation: 'VA'},
  { name: 'WASHINGTON', abbreviation: 'WA'},
  { name: 'WEST VIRGINIA', abbreviation: 'WV'},
  { name: 'WISCONSIN', abbreviation: 'WI'},
  { name: 'WYOMING', abbreviation: 'WY' }
]

//<Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>

const paperStyle={padding :20,height:'70vh',width:280, margin:"20px auto"}
    //const avatarStyle={backgroundColor:'#1bbd7e'}
    const h2Style={color:'#808080'}
    const gridStyle={color:'#ffffff', marginBottom: '10px'}
    const headerStyle={color:'#ffffff', marginBottom: '10px', fontSize: '28px', fontWeight: 'bold'}
    const btnstyle={margin:'8px 0'}

//import{ usStates } from './States';



//utility functions to put data returned from HubSpot into the format that Material UI data grid requires
function prepareColumns(){

   return [
    //{ field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'companyName',
      headerName: 'Company Name',
      width: 250
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 250
    },
    {
      field: 'city',
      headerName: 'City',
      width: 160,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 160,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 150
    },
    {
      field: 'authorized_territory',
      headerName: 'Authorized Territory',
      width: 150
    }
    
  ];

}

function prepareRows(data){
  let rows = [];
  for(let i = 0; i < data.length; i++){
    let row = data[i];
    let d = {};
    d.id = row.id;
    d.companyName = row.properties.name;
    d.address = row.properties.address;
    d.city = row.properties.city;
    d.state = row.properties.state;
    d.phoneNumber = row.properties.phone;
    d.authorized_territory = row.properties.authorized_territory;
    rows.push(d);
  }
  return rows;
}

export default function SumitomoDataGrid(props){ 

  //create some constants and methods to update them
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchType, setSearchType] = useState('state');
  const [searchText, setSearchText] = useState('');
  const [searchState, setSearchState] = useState('VT');
  const [showSearchText, setShowSearchText] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const [username, setUsername] = useState('-1');
  const [password, setPassword] = useState('-1');

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleUsernameTextChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordTextChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSearchTypeChange = (event) => {
    if(event.target.value === 'state'){
      setShowSearchText(false);
    } else {
      setShowSearchText(true);
    }
    setSearchType(event.target.value);
};

  const handleSearchStateChange = (event) => {
    setSearchState(event.target.value);
  };


  let searchCompanies = async () => {

    let stype = {searchType};
    console.log(stype);

    let stext = {searchText};
    console.log(stext); 

    let sstate = {searchState};
    console.log(sstate);

    let search = sstate.searchState;
    if(stype.searchType === 'company'){
      search = stext.searchText;
    }

    //note that when referencing the variable "value", you actually have to declare it - e.g. stype - and then explicitly get the
    //property, e.g. 'searchType', i.e. it doesn't inherently return the value
    let body = {
      "searchType": stype.searchType,
      "search": search
    }

    let response = await axios.post(`https://api.newbreedhub.com/integrations/sumitomo-lfi?action=search`,body);
    console.log(response.data);
    //local variable for the new rows
    let rows =  prepareRows(response.data);
    //local variable for the new columns
    let columns = prepareColumns();

    setRows(rows);
    setColumns(columns);

}

  
  useEffect( () => {

    console.log('inside initialization');

    async function loadCompanies(){
      console.log('LOADING COMPANIES');
      try{
        let response = await axios.get(`https://api.newbreedhub.com/integrations/sumitomo-lfi?action=search`);
        console.log(response.data);
        //local variable for the new rows
        let rows =  prepareRows(response.data);
        //local variable for the new columns
        let columns = prepareColumns();
    
        setRows(rows);
        setColumns(columns);
      } catch(e){}
    }

    async function checkAuth(){

      let data = sessionStorage.getItem("auth");
      console.log('SESSION STORAGE',data);

      if(data === 'true'){
        
        setAuthenticated(true);
       
      }
      await loadCompanies();

    }
    
    //check if authenticated
    console.log(authenticated)
    checkAuth();
   
  },[authenticated]);

  async function login(){

    console.log('LOGIN');
    console.log('USERNAME',username);
    console.log('PASSWORD',password);
    let loginEndpoint = `https://api.newbreedhub.com/integrations/sumitomo-lfi?action=login`;
    let data = {
      "username": username,
      "password": password
    }
    let resp = await axios.post(loginEndpoint,data,{});
    console.log('LOGIN RESP',resp.data);
    if(resp.data === 'authenticated'){
      setAuthenticated(true);
      sessionStorage.setItem("auth", "true");
    }

  }

  async function logout(){

    console.log('LOGOUT');

      setAuthenticated(false);
      sessionStorage.removeItem("auth");

  }
  
  return (

    <div style={{margin: '50px'}}>
   
    { authenticated ? (

     
<>
<img src={`/logo.png`} width="250" alt="Description" />

      <Box sx={{ flexGrow: 1 }}>


      <Grid container spacing={2}>
        <Grid item xs={12}>     <center><div style={headerStyle} >LFI Lookup Portal</div></center>
          </Grid>
        </Grid>


      <Grid container spacing={2}>
          
          <Grid item xs={3}>
           
          </Grid>
          <Grid item xs={3} style={gridStyle}>
            search type
           </Grid>
           <Grid item xs={3}  style={gridStyle}>
            {searchType}
           </Grid>
           <Grid item xs={3}>
           
           </Grid>
        </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}>
         
        </Grid>
        <Grid item xs={3}>
         
         </Grid>
         <Grid item xs={3}>
         
         </Grid>
         <Grid item xs={3}>
         <Button sx={{
          display: 'none',
                  background: 'linear-gradient(45deg, #2E008B 30%, #122889 90%)',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '10px',
                  boxShadow: '0px 3px 5px 2px rgba(255, 105, 135, .3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #2E008B 20%, #122889 80%)',
                  },
                }} variant="contained"  onClick={logout} style={{marginBottom: '40px'}}>Logout</Button>
         </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}>
         
        </Grid>
        <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="select-label"></InputLabel>
          <Select
          name="searchType"
            labelId="select-label"
            id="search-type"
            value={searchType}
            label="Filter Type"
            onChange={handleSearchTypeChange} 
            sx={{
              backgroundColor: 'white' , borderRadius: '10px'          
            }}
          >
          
            <MenuItem value={'state'}>State</MenuItem>
            <MenuItem value={'company'}>Company Name</MenuItem>
          </Select>
          
        </FormControl>
        </Grid>

        <Grid item xs={3}>
        {(showSearchText &&
    <FormControl fullWidth style={{marginTop: '0px'}}>
      <TextField id="search-text" sx={{
            backgroundColor: 'white', borderRadius: '10px'          
          }} value={searchText} label="" variant="outlined" onChange={handleSearchTextChange} />
    </FormControl>
    )}
    
    {(!showSearchText &&
        <FormControl  fullWidth style={{marginTop: '0px'}}>
          <InputLabel id="select-label"></InputLabel>
          <Select
            labelId="search-state-label"
            id="search-state"
            value={searchState}
            label=""
            onChange={handleSearchStateChange}
            sx={{
              backgroundColor: 'white' ,
              borderRadius: '10px'         
            }}
          >
            { usStates.map((state) => {
               return <MenuItem value={state.abbreviation}>{state.name}</MenuItem>
            } )}

            
          </Select>    
        </FormControl>
      )}
        </Grid>
        <Grid item xs={3}>
        <Button sx={{
                  background: 'linear-gradient(45deg, #2680d4 30%, #122889 90%)',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '10px',
                  
                  '&:hover': {
                    background: 'linear-gradient(45deg, #2680d4 20%, #122889 80%)',
                  },
                }} variant="contained"  onClick={searchCompanies} style={{marginTop: '0px'}}>Search Companies</Button>
        </Grid>

        <Grid container spacing={2}>

        <Grid item xs={3}>
         
        </Grid>
        <Grid item xs={3}>
          
         </Grid>
         <Grid item xs={3}>
          
         </Grid>
         <Grid item xs={3}>
         
         </Grid>
      </Grid>

      </Grid>
    </Box>
     
     <div style={{padding: '20px', backgroundColor: '#ffffff', marginTop: '20px',borderRadius: '10px'}}>
      <Box sx={{ height: 700, width: '100%' }}  style={{marginTop: '20px'}}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          //checkboxSelection
          //disableSelectionOnClick
          sx={{
            backgroundColor: 'white'          
          }}
        />
      </Box>
      </div>
      </>
    
    ) : (

      <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                    <img src={`/logo.jpg`} width="250" alt="Description" />
                    <h2 style={h2Style}>LFI Lookup Portal
                    </h2>
                </Grid>
                <TextField  onChange={handleUsernameTextChange} id="username" style={{marginBottom: '10px'}} label='Username' placeholder='Enter username' variant="outlined" fullWidth required/>
                <TextField  onChange={handlePasswordTextChange} id="password" label='Password' placeholder='Enter password' type='password' variant="outlined" fullWidth required/>
                
                <Button onClick={login} 
                sx={{
                  background: 'linear-gradient(45deg, #2680d4 30%, #122889 90%)',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '10px',
                  boxShadow: '0px 3px 5px 2px rgba(255, 105, 135, .3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #2680d4 30%, #122889 90%)',
                  },
                }}
                color='primary' variant="contained" style={btnstyle} fullWidth>Sign in</Button>
                
            </Paper>
        </Grid>

    ) }
</div>

  );
}

